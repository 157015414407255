export const fetchApi = (data) => {
    return new Promise(async (resolve, reject) => {
        switch (data.type) {
            case "GET":
                fetch(data.url).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject({ type: "network", message: "network error" })
                });
                break;
            case "POST":
                fetch(data.url, data.payload).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject({ type: "network", message: "network error" })
                });
                break;
            case "AUTH_GET":
                fetch(data.url, data.payload).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject({ type: "network", message: "network error" })
                });
            default:
                break;
        }
    });
}