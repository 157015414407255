import { useEffect, useState } from "react";
import CelebListComponent from "./CelebListComponent";
import { URL } from "./../utils/constants";
const FilmActors = () => {
    const [featured, setFeatured] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [arrow, setArrow] = useState(false);
    useEffect(async () => {
        try{
            const res = await fetch(`${URL}api/celebrity/by_categories?categoryIds=5f3920421c68b408f23bed1b`);
            const result = await res.json();
            setFeatured(result);
        }
        catch(e){
            console.log(e);
        }
    }, [])
    const handleArrow = () => {
        if (!arrow) {
            document.getElementById('filmElement').scrollLeft += 2000;
        }
        else {
            document.getElementById('filmElement').scrollLeft -= 2000;
        }
        setArrow(!arrow);
    }
    return (
        <CelebListComponent
            name="Kannada Film Actors"
            scrollId="filmElement"
            arrow={arrow}
            celebList={featured} handleArrow={handleArrow}
            cataegoryId="5f3920421c68b408f23bed1b"
        />
    )
}
export default FilmActors