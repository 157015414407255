import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logo from "./../assets/logo.png";
import { URL, HEADERS } from "../utils/constants";
import { useHistory } from "react-router-dom";
import { fetchApi } from "../services/services";

const Contact = () => {
    let history = useHistory();
    const [errorText, setErrorText] = useState();
    const[loading, setLoading] = useState(false)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobileNumber: '',
           
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('required'),
            lastName: Yup.string().required('required'),
            email: Yup.string().email('Invalid email address').required('required'),
            mobileNumber: Yup.string().required("required")
                .matches(phoneRegExp, 'Phone number is not valid'),
           
        }),

        onSubmit: async (values) => {
            try {
                setLoading(true)
                const payload = {
                    method: 'POST',
                    headers: HEADERS,
                    body: JSON.stringify({
                        "firstName": values.firstName,
                        "lastName": values.lastName,
                        "email": values.email,
                        "mobileNumber": values.mobileNumber,
                      
                    }),
                }
                const url = URL + `api/auth/contact`;
                const data = {
                    url,
                    payload,
                    type: "POST"
                }
                const response = await fetchApi(data);
                const result = await response.json();
                if (response.status === 201) {
                    localStorage.setItem('mobileNumber', values.mobileNumber);
                   console.log("Successfully Submitted")
                }
                else {
                    setErrorText(result.message)
                }
                setLoading(false)
            }
            catch (e) {
                setLoading(false)
                e?.message ? setErrorText(e.message) : setErrorText(e);
            }
            values.preventDefault();
        },
    });
    return (
        <>
            <TopBar />
            <Header />
            <Container fluid className="py-3 px-4 px-md-5">
                <Row noGutters className="login-container p-4 d-flex justify-content-center align-items-center" style={{ borderRadius: "10px" }}>
                   
                    <Form className="" style={{ textAlign: "center" }} onSubmit={formik.handleSubmit}>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="firstName"
                                placeholder="firstName"
                                id="firstNameInput"
                                className=""
                                {...formik.getFieldProps('firstName')}
                            />
                            {formik.touched.firstName && formik.errors.firstName ? (
                                <div className="error-text">{formik.errors.firstName}</div>
                            ) : null}
                            <label htmlFor="firstNameInput">First Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="lastName"
                                placeholder="lastName"
                                id="lastNameInput"
                                className=""
                                {...formik.getFieldProps('lastName')}
                            />
                            {formik.touched.lastName && formik.errors.lastName ? (
                                <div className="error-text">{formik.errors.lastName}</div>
                            ) : null}
                            <label htmlFor="lastNameInput">Last Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                id="emailInput"
                                className=""
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="error-text">{formik.errors.email}</div>
                            ) : null}
                            <label htmlFor="emailInput">email</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder=""
                                id="phoneInput"
                                className=""
                                {...formik.getFieldProps('mobileNumber')}
                            />
                            {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                                <div className="error-text">{formik.errors.mobileNumber}</div>
                            ) : null}
                            <label htmlFor="mobileNumberInput">Phone Number</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="textarea"
                                placeholder=""
                                id="phoneInput"
                                className=""
                                {...formik.getFieldProps('mobileNumber')}
                            />
                            {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                                <div className="error-text">{formik.errors.mobileNumber}</div>
                            ) : null}
                            <label htmlFor="mobileNumberInput">Your Message</label>
                        </div>
                      
                        <Button variant="primary" type="submit" style={{height:"50px", width: "150px", backgroundColor:"#4f989d", borderColor:"#4f989d"}}>
                            Submit
                            {loading && <Spinner 
                            as="span" animation="border" size="sm" role="status" aril-hidden="true" 
                            />}
                        </Button>
                        {errorText && <p className="error-text1">{errorText}</p>}
                    </Form>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
export default Contact;