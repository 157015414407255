import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logo from "./../assets/logo.png";
import { URL, HEADERS } from "../utils/constants";
import { useHistory } from "react-router-dom";
import { fetchApi } from "../services/services";

const CreateCelebrity = () => {
    let history = useHistory();
    const [errorText, setErrorText] = useState();
    const[loading, setLoading] = useState(false)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobileNumber: '',
            password: '',
            userName: '',
            categories: '',
            industries: '',
            aboutInfo: '',
            contactPerson: '',
            media: '',
            city: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('required'),
            lastName: Yup.string().required('required'),
            userName: Yup.string().required('required'),
            categories: Yup.string().required('required'),
            aboutInfo: Yup.string().required('required'),
            contactPerson: Yup.string().required('required'),
            city: Yup.string().required('required'),
        }),

        onSubmit: async (values) => {
            try {
                setLoading(true)
                const payload = {
                    method: 'POST',
                    headers: HEADERS,
                    body: JSON.stringify({
                        "firstName": values.firstName,
                        "lastName": values.lastName,
                        "email": values.email,
                        "mobileNumber": values.mobileNumber,
                        "password": values.password,
                        "userName": values.userName,
                        "categories": values.categories,
                        "industries": values.industries,
                        "aboutInfo": values.aboutInfo,
                        "contactPerson": values.contactPerson,
                        "media": values.media,
                        "city": values.city,
                    }),
                }
                const url = URL + `api/celebrity/create`;
                const data = {
                    url,
                    payload,
                    type: "POST"
                }
                const response = await fetchApi(data);
                const result = await response.json();
                if (response.status === 200) {
                    history.push({
                        pathname: '/home'
                    })
                }
                else {
                    setErrorText(result.message)
                }
                setLoading(false)
            }
            catch (e) {
                setLoading(false)
                e?.message ? setErrorText(e.message) : setErrorText(e);
            }
            values.preventDefault();
        },
    });
    return (
        <>
            <TopBar />
            <Header />
            <Container fluid className="py-3 px-4 px-md-5">
                <Row noGutters className="login-container p-4 d-flex justify-content-center align-items-center" style={{ borderRadius: "10px" }}>
                    <Col md={12} className="d-flex justify-content-center align-items-center">
                        <img src={logo} width="75" height="75" />
                    </Col>
                    <Form className="" style={{ textAlign: "center" }} onSubmit={formik.handleSubmit}>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="firstName"
                                placeholder="firstName"
                                id="firstNameInput"
                                className=""
                                {...formik.getFieldProps('firstName')}
                            />
                            {formik.touched.firstName && formik.errors.firstName ? (
                                <div className="error-text">{formik.errors.firstName}</div>
                            ) : null}
                            <label htmlFor="firstNameInput">First Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="lastName"
                                placeholder="lastName"
                                id="lastNameInput"
                                className=""
                                {...formik.getFieldProps('lastName')}
                            />
                            {formik.touched.lastName && formik.errors.lastName ? (
                                <div className="error-text">{formik.errors.lastName}</div>
                            ) : null}
                            <label htmlFor="lastNameInput">Last Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                id="emailInput"
                                className=""
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="error-text">{formik.errors.email}</div>
                            ) : null}
                            <label htmlFor="emailInput">email</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="mobile Number"
                                id="phoneInput"
                                className=""
                                {...formik.getFieldProps('mobileNumber')}
                            />
                            {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                                <div className="error-text">{formik.errors.mobileNumber}</div>
                            ) : null}
                            <label htmlFor="mobileNumberInput">Phone Number</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="password"
                                placeholder="password"
                                id="passwordInput"
                                className=""
                                {...formik.getFieldProps('password')}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="error-text">{formik.errors.password}</div>
                            ) : null}
                            <label htmlFor="passwordInput">Password</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Username"
                                id="userNameInput"
                                className=""
                                {...formik.getFieldProps('userName')}
                            />
                            {formik.touched.userName && formik.errors.userName ? (
                                <div className="error-text">{formik.errors.userName}</div>
                            ) : null}
                            <label htmlFor="userNameInput">Username</label>
                        </div>

                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="categories"
                                id="categoriesInput"
                                className=""
                                {...formik.getFieldProps('categories')}
                            />
                            {formik.touched.categories && formik.errors.categories ? (
                                <div className="error-text">{formik.errors.categories}</div>
                            ) : null}
                            <label htmlFor="categoriesInput">Categories</label>
                        </div>

                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="industries"
                                id="industriesInput"
                                className=""
                                {...formik.getFieldProps('industries')}
                            />
                            {formik.touched.industries && formik.errors.industries ? (
                                <div className="error-text">{formik.errors.industries}</div>
                            ) : null}
                            <label htmlFor="industriesInput">Industries</label>
                        </div>

                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="About Celebrity"
                                id="aboutInfoInput"
                                className=""
                                {...formik.getFieldProps('aboutInfo')}
                            />
                            {formik.touched.aboutInfo && formik.errors.aboutInfo ? (
                                <div className="error-text">{formik.errors.aboutInfo}</div>
                            ) : null}
                            <label htmlFor="industriesInput">About Celebrity</label>
                        </div>

                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Contact Person"
                                id="contactPersonInput"
                                className=""
                                {...formik.getFieldProps('contactPerson')}
                            />
                            {formik.touched.contactPerson && formik.errors.contactPerson ? (
                                <div className="error-text">{formik.errors.contactPerson}</div>
                            ) : null}
                            <label htmlFor="contactPersonInput">Contact Person</label>
                        </div>

                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="City"
                                id="cityInput"
                                className=""
                                {...formik.getFieldProps('city')}
                            />
                            {formik.touched.city && formik.errors.city ? (
                                <div className="error-text">{formik.errors.city}</div>
                            ) : null}
                            <label htmlFor="contactPersonInput">City</label>
                        </div>
                        <Button variant="primary" type="submit">
                            Submit
                            {loading && <Spinner 
                            as="span" animation="border" size="sm" role="status" aril-hidden="true"
                            />}
                        </Button>
                        {errorText && <p className="error-text1">{errorText}</p>}
                    </Form>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
export default CreateCelebrity;