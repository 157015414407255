import {
    HashRouter as BrowserRouter,
    Route,
    Redirect,
} from 'react-router-dom';
import React, { createContext } from "react";
import HomePage from "./../pages/Homepage";
import Celebrity from "./../pages/Celebrity";
import View from "./../pages/View";
import Login from "./../pages/Login";
import SignUp from "./../pages/SignUp";
import Otp from "./../pages/Otp";
import ForgotPassword from "./../pages/ForgotPassword";
import CreateCelebrity from "./../pages/CreateCelebrity";
import Contact from "./../pages/Contact";
import { useSelector } from "react-redux";

const Router = () => {
    return (
        <ProvideAuth>
            <BrowserRouter>
                <Route exact path="/">
                    <HomePage />
                </Route>
                <Route exact path="/celebrity/:id">
                    <Celebrity />
                </Route>
                <Route exact path="/view/:id">
                    <View />
                </Route>
                <AuthRoute exact path="/login" >
                    <Login />
                </AuthRoute>
                <AuthRoute exact path="/SignUp">
                    <SignUp />
                </AuthRoute>
                <AuthRoute exact path="/Otp">
                    <Otp />
                </AuthRoute>
                <AuthRoute exact path="/ForgotPassword">
                    <ForgotPassword />
                </AuthRoute>
                <AuthRoute exact path="/CreateCelebrity">
                    <CreateCelebrity />
                </AuthRoute>
                <AuthRoute exact path="/Contact">
                    <Contact />
                </AuthRoute>
            </BrowserRouter>
        </ProvideAuth>
    )
}

const authContext = createContext();

const ProvideAuth = ({ children }) => {
    const userToken = useSelector(state => state.authReducer.token);
    return (
        <authContext.Provider value={userToken}>
            {children}
        </authContext.Provider>
    );
}

const PrivateRoute = ({ children, ...rest }) => {
    const userToken = useSelector(state => state.authReducer.token);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                userToken ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

const AuthRoute = ({ children, ...rest }) => {
    const userToken = useSelector(state => state.authReducer.token);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !userToken ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}
export default Router