import { useEffect } from 'react';
import TopBar from './../components/TopBar';
import Header from './../components/Header';
import Slider from "./../components/Slider";
import Banner from "./../components/Banner";
import Featured from "./../components/Featured";
import FilmActors from './../components/FilmActors';
import SerialActors from './../components/SerialActors';
import Singers from './../components/Singers';
import Bigbosskannada from './../components/Bigbosskannada';
import Footer from './../components/Footer';
const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <TopBar />
            <Header />
            <Slider />
            <Banner />
            <Featured />
            <FilmActors />
            <SerialActors />
            <Bigbosskannada />
            <Singers />
            <Footer />
        </>
    )
}
export default HomePage