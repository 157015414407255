import React, { useState, useEffect } from "react";
import TopBar from './../components/TopBar';
import Header from './../components/Header';
import Footer from "../components/Footer";
import { fetchApi } from "../services/services";
import { useHistory, useParams } from "react-router-dom";
import { URL, HEADERS } from "./../utils/constants";
import { Row, Col, ListGroup } from "react-bootstrap";

const View = () => {
    const history = useHistory();
    const [celebrities, setCelebrities] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    useEffect(async () => {
        try {
            const data = {
                url: URL + `api/celebrity/${id === "featured" ? "featured" : "by_categories?categoryIds=" + id}`,
                type: "GET"
            }
            const response = await fetchApi(data);
            if (response.status === 200) {
                const result = await response.json();
                setCelebrities(result);
            }
        }
        catch (e) {
            console.log(e)
        }
    }, [])
    const handleClick = ({ _id }) => {
        history.push(`/celebrity/${_id}`)
    }
    return (
        <>
            <TopBar />
            <Header />
            <Row>
                {celebrities.map((data) =>
                    <Col key={data.id} className="" lg={2} md={3} sm={4} xs={6}>
                        <ListGroup.Item key={data._id} style={{ cursor: "pointer" }} onClick={() => handleClick(data)}>
                            <img src={data.profileImage} alt="My Celebrity" className="center imgList1" draggable={false} />
                            <div className="px-2 text-featured">
                                <p className="my-0" style={{ fontWeight: "600" }}>{data.firstName + data.lastName}</p>
                                <p style={{ fontWeight: "lighter", fontSize: "small" }}>{data.firstName}</p>
                            </div>
                        </ListGroup.Item>
                    </Col>
                )}
            </Row>
            <Footer />
        </>
    )
}
export default View