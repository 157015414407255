import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TopBar from './../components/TopBar';
import Header from './../components/Header';
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { URL, HEADERS } from "./../utils/constants";
import { fetchApi } from "./../services/services";
import { dateToTimeStamp } from "../utils/helper";
import Swal from "sweetalert2";
const Celebrity = () => {
    const userToken = useSelector(state => state.authReducer.token);
    const userId = useSelector(state => state.authReducer.userId);
    const [celebDetails, setCelebDetails] = useState();
    const [userPlans, setUserPlans] = useState([]);
    const [errorText, setErrorText] = useState();
    const [loading, setLoading] = useState(false);
    const [celebLoader, setCelebLoader] = useState(true);
    const history = useHistory();
    const { id } = useParams();
    useEffect(async () => {
        window.scrollTo(0, 0);
        try {
            const res = await fetch(`${URL}api/celebrity/id?id=${id}`);
            const resJson = await res.json();
            setCelebDetails(resJson);
            setCelebLoader(false);
            if (userToken) {
                const url = URL + `api/plan`;
                const accessToken = `Bearer ${userToken}`
                const payload = {
                    method: "GET",
                    headers: { ...HEADERS, 'Authorization': accessToken }
                }
                const data = {
                    url,
                    type: "AUTH_GET",
                    payload
                }
                const response = await fetchApi(data);
                const result = await response.json();
                setUserPlans(result);
            }
        }
        catch (e) {
            setCelebLoader(false);
            console.log(e)
        }
    }, [])
    const formik = useFormik({
        initialValues: {
            choosePlan: '',
            socialLink: '',
            brandName: '',
            budget: '',
            preferredDate: '',
            description: '',
        },
        validationSchema: Yup.object({
            brandName: Yup.string().required("required"),
            choosePlan: Yup.string().required("required"),
            socialLink: Yup.string().required("required"),
            budget: Yup.string().required("required"),
            preferredDate: Yup.string().required("required"),
            description: Yup.string().required("required")
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const url = URL + `api/request/create`;
                const accessToken = `Bearer ${userToken}`
                const payload = {
                    method: "POST",
                    headers: { ...HEADERS, 'Authorization': accessToken },
                    body: JSON.stringify({
                        "celebrityId": id,
                        "userId": userId,
                        "celebPlanIds": [values.choosePlan],
                        "socialLink": values.socialLink,
                        "budget": values.budget,
                        "businessName": values.brandName,
                        "description": values.description,
                        "files": [
                            "string"
                        ],
                        "preferredDateTime": dateToTimeStamp(values.preferredDate)
                    })
                }
                const data = {
                    url,
                    type: "POST",
                    payload
                }
                const response = await fetchApi(data);
                const result = await response.json();
                if (response.status === 201) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Your work has been saved',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    formik.resetForm();
                }
                else {
                    setErrorText(result.message)
                }
                setLoading(false);
            }
            catch (e) {
                setLoading(false)
                console.log(e)
            }
            values.preventDefault();
        }
    });
    const handleButtonClick = () => {
        history.push("/login")
    }

    return (
        <>
            <TopBar />
            <Header />
            <Container fluid className="py-3 px-4 px-md-5">
                {
                    celebLoader ? <div className="celeb-loader">Loading Celebrity Details...</div> : <Row noGutters className="m-md-3" style={{ background: "#ffffff", borderRadius: "25px" }}>
                        <Col md={3} className="d-flex align-items-center justify-content-center p-3">
                            <img src={celebDetails.profileImage} alt="Paris" className="center imgList2  img-fluid" />
                        </Col>
                        <Col md={9} className="py-5"
                        // className="d-flex align-items-center justify-content-center"
                        >
                            <div>
                                <h2>{celebDetails.firstName + " " + celebDetails.lastName}</h2>
                                <h5>{celebDetails.celebrityInfo.industries[0]}</h5>
                            </div>
                            <div>
                                <p>
                                    {celebDetails.celebrityInfo.aboutInfo}
                                </p>
                            </div>
                            <div>
                            </div>
                        </Col>
                    </Row>
                }

                <Row className="m-md-3 my-3" style={{ padding: '2rem', background: "#5f0a87", borderRadius: "10px", fontWeight: "bold", textAlign: "center" }}>
                    <Col className="p-3" style={{color:"white"}}>
                        Social Media Post
                    </Col>
                    <Col className="p-3 border-right" style={{color:"white"}}>
                        Brand Photoshoot
                    </Col>
                    <Col className="p-3 border-right" style={{color:"white"}}>
                        Store Inauguration
                    </Col>
                    <Col className="p-3" style={{color:"white"}}>
                        Brand Shout out
                    </Col>

                </Row>
                {!userToken && <Row className="" style={{ borderRadius: "10px", fontWeight: "bold", textAlign: "center" }}>
                    <Button onClick={handleButtonClick} style={{ backgroundColor:"#9b6bd1",borderColor:"#5f0a87"}}>
                        Please Login to Book Your Celebrity
                    </Button>
                </Row>}
                {userToken && <Row className="m-md-3 my-3" style={{ background: "#ffffff", borderRadius: "10px", fontWeight: "bold", textAlign: "center" }}>
                    <Col md={12} className="p-3">
                        <h3 style={{ color: "#4f989d" }}> Fill The Details</h3>
                    </Col>
                    <Form className="form-padding" onSubmit={formik.handleSubmit}>
                        <div>
                            <Form.Label style={{ float: "left" }}> Choose Your Plan</Form.Label>
                            <Form.Control
                                as="select"
                                id="choosePlan"
                                className=""
                                {...formik.getFieldProps('choosePlan')}
                            > <option value="">Choose Your Plan</option>
                                {userPlans.map((data, index) => <option key={data._id} value={data._id}>{data.planName}</option>)}
                            </Form.Control>
                            {formik.touched.choosePlan && formik.errors.choosePlan ? (
                                <div className="error-text">{formik.errors.choosePlan}</div>
                            ) : null}
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Website/Social Media Link"
                                id="socialInput"
                                className=""
                                {...formik.getFieldProps('socialLink')}
                            />
                            {formik.touched.socialLink && formik.errors.socialLink ? (
                                <div className="error-text">{formik.errors.socialLink}</div>
                            ) : null}
                            <label htmlFor="socialInput">Social Media/Website Link</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Branad/Business Name"
                                id="brandInput"
                                className=""
                                {...formik.getFieldProps('brandName')}
                            />
                            {formik.touched.brandName && formik.errors.brandName ? (
                                <div className="error-text">{formik.errors.brandName}</div>
                            ) : null}
                            <label htmlFor="brandInput">Brand/Business Name</label>
                        </div>

                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Choose Your Plan"
                                id="budgetInput"
                                className=""
                                {...formik.getFieldProps('budget')}
                            />
                            {formik.touched.budget && formik.errors.budget ? (
                                <div className="error-text">{formik.errors.budget}</div>
                            ) : null}
                            <label htmlFor="budgetInput"> Your Budget</label>
                        </div>
                        <div>
                            <Form.Label style={{ float: "left" }}>
                                Preferred Date
                            </Form.Label>
                            <Form.Control
                                type="date"
                                min={JSON.stringify(new Date()).slice(1, 11)}
                                placeholder="Preferred Date"
                                id="dateInput"
                                className=""
                                {...formik.getFieldProps('preferredDate')}
                            />
                            {formik.touched.preferredDate && formik.errors.preferredDate ? (
                                <div className="error-text">{formik.errors.preferredDate}</div>
                            ) : null}
                            <label htmlFor=""></label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                as="textarea"
                                style={{ height: '100px' }}
                                placeholder="Description"
                                id="floatingInput"
                                className=""
                                {...formik.getFieldProps('description')}
                            />
                            {formik.touched.description && formik.errors.description ? (
                                <div className="error-text">{formik.errors.description}</div>
                            ) : null}
                            <label htmlFor="floatingInput">Write About Your Business and Promotion</label>
                        </div>
                        <Button variant="primary" type="submit" style={{ height: "50px", width: "150px", backgroundColor: "#5f0a87", borderColor: "##5f0a87" }}>
                            Submit
                            {loading && <Spinner
                                as="span" animation="border" size="sm" role="status" aril-hidden="true"
                            />}
                        </Button>
                        {errorText && (<p>{errorText}</p>)}
                    </Form>
                </Row>}
            </Container>
            <Footer />
        </>
    )
};
export default Celebrity