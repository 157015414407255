const initialState = {
    celebrity: null
};

const celebReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_CELEB":
            return { ...state, celebrity: action.payload };
        case "REMOVE_CELEB":
            return { ...state, celebrity: null };
        default:
            return state;
    }
    return initialState
}
export default celebReducer;