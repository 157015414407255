const initialState = {
    token: null,
    userId: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TOKEN":
            return { ...state, token: action.payload.token, userId: action.payload.userId };
        case "REMOVE_TOKEN":
            return { ...state, token: null, userId: null }
        default:
            return state;
    }
}
export default authReducer;