import { Container, Row, Col, ListGroup } from "react-bootstrap";
import logo from "./../assets/logo-footer.png";
const Footer = () => {
    return (
        <Container fluid style={{ background: "#9b6bd1" }}>
            <Row noGutters>
                <Col sm={12} md={8} lg={6}>
                    <img src={logo} width="200" height="125" />
                    <p style={{textAlign:"justify"}}>
                        A social platform for brands and businesses to engage celebrities and influencers for brand promotions and brand engagements. A one-stop solution for small, medium and large scale brands to connect to the celebrities of their choice and help close the gap between brands and their endorsers.
                    </p>
                </Col>
                <Col sm={12} md={4} lg={2} className="mt-sm-2 pt-sm-2 mt-md-5 pt-md-5 mt-lg-5 pt-lg-5 ">
                    <h4>Contact</h4>
                    <p>+919986516463</p>
                    <p>info@bookyourceleb.com </p>
                </Col>
                <Col sm={12} md={8} lg={2} className="mt-lg-5 pt-lg-5">
                    <h4>Quick Links</h4>
                    <p>About Us</p>
                    <p>Contact Us</p>

                </Col>
                <Col sm={12} md={4} lg={2} className="mt-lg-5 pt-lg-5">
                    <h4>Other Links</h4>
                    <p>Privacy Policy</p>
                    <p>Return Policy</p>
                    <p>Terms and Condition</p>
                </Col>
            </Row>
        </Container>
    )
}
export default Footer