import { useEffect, useState } from "react";
import CelebListComponent from "./CelebListComponent";
import { URL } from "./../utils/constants";
const Singers = () => {
    const [featured, setFeatured] = useState([]);
    const [arrow, setArrow] = useState(false);
    useEffect(async () => {
        const res = await fetch(`${URL}api/celebrity/by_categories?categoryIds=5f3920421c68b408f23bed2c`);
        const result = await res.json();
        setFeatured(result);
    }, [])
    const handleArrow = () => {
        if (!arrow) {
            document.getElementById('singerElement').scrollLeft += 2000;
        }
        else {
            document.getElementById('singerElement').scrollLeft -= 2000;
        }
        setArrow(!arrow);
    }
    return (
        <CelebListComponent
            name="Kannada Singers"
            scrollId="singerElement"
            arrow={arrow}
            celebList={featured} handleArrow={handleArrow}
            cataegoryId="5f3920421c68b408f23bed2c"
        />
    )
}
export default Singers