import { Carousel } from "react-bootstrap";
import sl1 from "./../assets/1.jpg";
import sl2 from "./../assets/2.jpg";
import sl3 from "./../assets/slider5.jpg";
import sl4 from "./../assets/slider8.jpg";
const Slider = () => {
    return (
        <Carousel fade>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={sl4}
                    alt="First slide"
                    style={{ maxHeight: "500px"}}
                />
               
            </Carousel.Item>
           
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={sl3}
                    alt="Third slide"
                    style={{ maxHeight: "500px"}}
                />

               
            </Carousel.Item>
        </Carousel>
    )
}
export default Slider