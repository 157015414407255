import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logo from "./../assets/logo.png";
import { useState } from "react";
import { URL, HEADERS } from "./../utils/constants";
import { useHistory } from "react-router-dom";
import { fetchApi } from "./../services/services";

const Otp = () => {
    const [errorText, setErrorText] = useState();
    const [loading, setLoading] = useState();
    let history = useHistory();
    const formik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string().required('required')
        }),

        onSubmit: async (values) => {
            try {
                setLoading(true)
                const payload = {
                    method: 'POST',
                    headers: HEADERS,
                    body: JSON.stringify({
                        "mobileNumber": localStorage.getItem('mobileNumber'),
                        "otp": values.otp
                    }),
                }
                const url = URL + `api/auth/validate_otp`;
                const data = {
                    url,
                    payload,
                    type: "POST"
                }
                const response = await fetchApi(data);
                if (response.status === 201) {
                    localStorage.removeItem('mobileNumber');
                    history.push('/login');
                }
                else {
                    const result = await response.json();
                    setErrorText(result.message)
                }
                setLoading(false)
            }
            catch (e) {
                setLoading(false)
                e?.message ? setErrorText(e.message) : setErrorText(e);
            }
            values.preventDefault()
        },
    });
    return (
        <>
            <TopBar />
            <Header />
            <Container fluid className="py-3 px-4 px-md-5">
                <Row noGutters className="login-container p-4 d-flex justify-content-center align-items-center" style={{ borderRadius: "10px" }}>
                    <Col md={12} className="d-flex justify-content-center align-items-center">
                        <img src={logo} width="235" height="125" />
                    </Col>
                    <Form className="" style={{ textAlign: "center" }} onSubmit={formik.handleSubmit}>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="enter otp"
                                autoComplete="off"
                                id="otpInput"
                                className=""
                                {...formik.getFieldProps('otp')}
                            />
                            {formik.touched.otp && formik.errors.otp ? (
                                <div className="error-text">{formik.errors.otp}</div>
                            ) : null}
                            <label htmlFor="otpInput">OTP</label>
                        </div>
                        <Button variant="primary" type="submit" style={{height:"50px", width: "150px", backgroundColor:"#9b6bd1", borderColor:"#9b6bd1"}}>
                            Submit
                            {loading && <Spinner
                                as="span" animation="border" size="sm" role="status" aril-hidden="true"
                            />}
                        </Button>
                        {errorText && <p className="error-text1">{errorText}</p>}
                    </Form>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
export default Otp;
