import { useEffect, useState } from "react";
import CelebListComponent from "./CelebListComponent";
import { fetchApi } from "../services/services";
import { URL } from "./../utils/constants";
const Featured = () => {
    const [featured, setFeatured] = useState([]);
    const [arrow, setArrow] = useState(false);
    useEffect(async () => {
        try {
            const data = {
                url: URL + "api/celebrity/featured",
                type: "GET"
            }
            const response = await fetchApi(data);
            if (response.status === 200) {
                const result = await response.json();
                setFeatured(result);
            }
        }
        catch (e) {
            console.log(e)
        }
    }, [])
    const handleArrow = () => {
        if (!arrow) {
            document.getElementById('dstElement').scrollLeft += 2000;
        }
        else {
            document.getElementById('dstElement').scrollLeft -= 2000;
        }
        setArrow(!arrow);
    }
    return (
        <CelebListComponent
            name="Featured"
            scrollId="dstElement"
            arrow={arrow}
            celebList={featured} handleArrow={handleArrow}
            cataegoryId="featured"
        />
    )
}
export default Featured