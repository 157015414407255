import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logo from "./../assets/logo.png";
import { URL, HEADERS } from "./../utils/constants";
import { useHistory } from "react-router-dom";
import { fetchApi } from "./../services/services";
import { useDispatch } from "react-redux";
const ForgotPassword = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [errorText, setErrorText] = useState();
    const [loading, setLoading] = useState(false)
    const phoneRegExp = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
    const formik = useFormik({
        initialValues: {
            mobileNumber: '',
            newPassword: ''
        },
        validationSchema: Yup.object({
            mobileNumber: Yup.string().required("required")
                .matches(phoneRegExp, 'Phone number is not valid'),
            newPassword: Yup.string().required("required").min(7, "Password should contain atleast 7 characters")
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true)
                const payload = {
                    method: 'POST',
                    headers: HEADERS,
                    body: JSON.stringify({
                        "mobileNumber": values.mobileNumber,
                        "newPassword": values.newPassword
                    })
                }
                const url = URL + `api/auth/forgot_password`;
                const data = {
                    url,
                    payload,
                    type: "POST"
                }
                const response = await fetchApi(data);
                
                if (response.status === 201) {
                    localStorage.setItem('mobileNumber', values.mobileNumber);
                    history.push({
                        pathname: '/Otp'
                    })
                    }
                else {
                    const result = await response.json();
                    setErrorText(result.message)
                }
            }
            catch (e) {
                e?.message ? setErrorText(e.message) : setErrorText(e);
            }
            values.preventDefault()
        },
    });
    return (
        <>
            <TopBar />
            <Header />
            <Container fluid className="py-3 px-4 px-md-5">
                <Row noGutters className="login-container p-4 d-flex justify-content-center align-items-center" style={{ borderRadius: "10px" }}>
                    <Col md={12} className="d-flex justify-content-center align-items-center">
                        <img src={logo} width="235" height="125" />
                    </Col>
                    <Form className="" style={{ textAlign: "center" }} onSubmit={formik.handleSubmit}>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Phone Number"
                                id="phoneInput"
                                className=""
                                {...formik.getFieldProps('mobileNumber')}
                            />
                            {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                                <div className="error-text">{formik.errors.mobileNumber}</div>
                            ) : null}
                            <label htmlFor="phoneInput">Phone Number</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="password"
                                placeholder="password"
                                id="passwordInput"
                                className=""
                                {...formik.getFieldProps('newPassword')}
                            />
                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                <div className="error-text">{formik.errors.newPassword}</div>
                            ) : null}
                            <label htmlFor="passwordInput">New Password</label>
                        </div>
                        <Button variant="primary" type="submit" style={{height:"50px", width: "150px", backgroundColor:"#9b6bd1", borderColor:"#9b6bd1"}}>
                            Submit
                        </Button>
                        {errorText && (<p>{errorText}</p>)}
                    </Form>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
export default ForgotPassword