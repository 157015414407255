import { useEffect, useState } from "react";
import CelebListComponent from "./CelebListComponent";
import { URL } from "./../utils/constants";
const SerialActors = () => {
    const [featured, setFeatured] = useState([]);
    const [arrow, setArrow] = useState(false);
    useEffect(async () => {
        const res = await fetch(`${URL}api/celebrity/by_categories?categoryIds=5f391fb60d901a5552a5286a`)
        const result = await res.json();
        setFeatured(result);
    }, [])
    const handleArrow = () => {
        if (!arrow) {
            document.getElementById('serialElement').scrollLeft += 2000;
        }
        else {
            document.getElementById('serialElement').scrollLeft -= 2000;
        }
        setArrow(!arrow);
    }
    return (
        <CelebListComponent
            name="Kannada Serial Actors"
            scrollId="serialElement"
            arrow={arrow}
            celebList={featured} handleArrow={handleArrow}
            cataegoryId="5f391fb60d901a5552a5286a"
        />
    )
}
export default SerialActors