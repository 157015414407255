import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import {Link} from "react-router-dom";
import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logo from "./../assets/logo.png";
import { URL, HEADERS } from "./../utils/constants";
import { useHistory } from "react-router-dom";
import { fetchApi } from "./../services/services";

const SignUp = () => {
    let history = useHistory();
    const [errorText, setErrorText] = useState();
    const[loading, setLoading] = useState(false)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobileNumber: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('required'),
            lastName: Yup.string().required('required'),
            email: Yup.string().email('Invalid email address').required('required'),
            mobileNumber: Yup.string().required("required")
                .matches(phoneRegExp, 'Phone number is not valid'),
            password: Yup.string().required("Password should contain atleast 7 characters").min(7, "Password should contain atleast 7 characters"),
            confirmPassword: Yup.string().required("password must match").oneOf([Yup.ref("password"), null], "password must match")
        }),

        onSubmit: async (values) => {
            try {
                setLoading(true)
                const payload = {
                    method: 'POST',
                    headers: HEADERS,
                    body: JSON.stringify({
                        "firstName": values.firstName,
                        "lastName": values.lastName,
                        "email": values.email,
                        "mobileNumber": values.mobileNumber,
                        "password": values.password,
                    }),
                }
                const url = URL + `api/auth/register`;
                const data = {
                    url,
                    payload,
                    type: "POST"
                }
                const response = await fetchApi(data);
                const result = await response.json();
                if (response.status === 201) {
                    localStorage.setItem('mobileNumber', values.mobileNumber);
                    history.push({
                        pathname: '/Otp'
                    })
                }
                else {
                    setErrorText(result.message)
                }
                setLoading(false)
            }
            catch (e) {
                setLoading(false)
                e?.message ? setErrorText(e.message) : setErrorText(e);
            }
            values.preventDefault();
        },
    });
    return (
        <>
            <TopBar />
            <Header />
            <Container fluid className="py-3 px-4 px-md-5">
                <Row noGutters className="login-container p-4 d-flex justify-content-center align-items-center" style={{ borderRadius: "10px" }}>
                    <Col md={12} className="d-flex justify-content-center align-items-center">
                        <img src={logo} width="235" height="125" />
                    </Col>
                    <Form className="" style={{ textAlign: "center" }} onSubmit={formik.handleSubmit}>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="firstName"
                                placeholder="firstName"
                                id="firstNameInput"
                                className=""
                                {...formik.getFieldProps('firstName')}
                            />
                            {formik.touched.firstName && formik.errors.firstName ? (
                                <div className="error-text">{formik.errors.firstName}</div>
                            ) : null}
                            <label htmlFor="firstNameInput">First Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="lastName"
                                placeholder="lastName"
                                id="lastNameInput"
                                className=""
                                {...formik.getFieldProps('lastName')}
                            />
                            {formik.touched.lastName && formik.errors.lastName ? (
                                <div className="error-text">{formik.errors.lastName}</div>
                            ) : null}
                            <label htmlFor="lastNameInput">Last Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                id="emailInput"
                                className=""
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="error-text">{formik.errors.email}</div>
                            ) : null}
                            <label htmlFor="emailInput">email</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder=""
                                id="phoneInput"
                                className=""
                                {...formik.getFieldProps('mobileNumber')}
                            />
                            {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                                <div className="error-text">{formik.errors.mobileNumber}</div>
                            ) : null}
                            <label htmlFor="mobileNumberInput">Phone Number</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="password"
                                placeholder="password"
                                id="passwordInput"
                                className=""
                                {...formik.getFieldProps('password')}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="error-text">{formik.errors.password}</div>
                            ) : null}
                            <label htmlFor="passwordInput">Password</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="password"
                                placeholder="confirm Password"
                                id="confirmPasswordInput"
                                className=""
                                {...formik.getFieldProps('confirmPassword')}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                <div className="error-text">{formik.errors.confirmPassword}</div>
                            ) : null}
                            <label htmlFor="confirmPasswordInput">Confirm Password</label>
                        </div>
                        <Button variant="primary" type="submit" style={{height:"50px", width: "150px", backgroundColor:"#9b6bd1", borderColor:"#9b6bd1"}}>
                            Submit
                            {loading && <Spinner 
                            as="span" animation="border" size="sm" role="status" aril-hidden="true" 
                            />}
                        </Button>
                        {errorText && <p className="error-text1">{errorText}</p>}
                        <p><br></br>Already have an account ? <Link to="/Login" className=""style={{ backgroundColor:"ffffff", color:"#9b6bd1", borderColor:"#9b6bd1"}}><b>Login</b></Link  ></p>
                    </Form>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
export default SignUp;