import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { Button, Navbar, Nav, NavDropdown, FormControl } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { fetchApi } from "./../services/services";
import { URL, HEADERS } from "./../utils/constants";
import logo from "./../assets/logo.png";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addToken } from "../redux/actions/action";

const Login = () => {
    const dispatch = useDispatch();
    const [errorText, setErrorText] = useState();
    const [loading, setLoading] = useState(false)
    const phoneRegExp = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
    const formik = useFormik({
        initialValues: {
            phoneNumber: '',
            password: ''
        },
        validationSchema: Yup.object({
            phoneNumber: Yup.string().required("required")
                .matches(phoneRegExp, 'Phone number is not valid'),
            password: Yup.string().required("required").min(7, "Password should contain atleast 7 characters")
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true)
                const payload = {
                    method: 'POST',
                    headers: HEADERS,
                    body: JSON.stringify({
                        "mobileNumber": values.phoneNumber,
                        "password": values.password
                    })
                }
                const url = URL + `api/auth/login`;
                const data = {
                    url,
                    payload,
                    type: "POST"
                }
                const response = await fetchApi(data);
                const result = await response.json();
                if (response.status === 201) {
                    localStorage.setItem("token", result.token);
                    localStorage.setItem("userId", result.user._id)
                    dispatch(addToken({ token: result.token, userId: result.user._id }));
                }
                else {
                    setErrorText(result.message)
                }
                setLoading(false)
            }
            catch (e) {
                setLoading(false)
                e?.message ? setErrorText(e.message) : setErrorText(e);
            }
            values.preventDefault()
        },
    });
    return (
        <>
            <TopBar />
            <Header />
            <Container fluid className="py-3 px-4 px-md-5">
                <Row noGutters className="login-container p-4 d-flex justify-content-center align-items-center" style={{ borderRadius: "10px" }}>
                    <Col md={12} className="d-flex justify-content-center align-items-center">
                        <img src={logo} width="235" height="125" />
                    </Col>
                    <Form className="" style={{ textAlign: "center" }} onSubmit={formik.handleSubmit}>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Phone Number"
                                id="phoneInput"
                                className=""
                                {...formik.getFieldProps('phoneNumber')}
                            />
                            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                <div className="error-text">{formik.errors.phoneNumber}</div>
                            ) : null}
                            <label htmlFor="phoneInput">Phone Number</label>
                        </div>
                        <div className="form-floating mb-3">
                            <Form.Control
                                type="password"
                                placeholder="password"
                                id="passwordInput"
                                className=""
                                {...formik.getFieldProps('password')}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="error-text">{formik.errors.password}</div>
                            ) : null}
                            <label htmlFor="passwordInput">Password</label>
                        </div>
                        <Button variant="primary" type="submit" style={{height:"50px", width: "150px", backgroundColor:"#9b6bd1", borderColor:"#9b6bd1"}}>
                            Submit
                            {loading && <Spinner
                                as="span" animation="border" size="sm" role="status" aril-hidden="true"
                            />}
                        </Button>
                        {errorText && (<p>{errorText}</p>)}
                        <p><br></br>Forgot Password ? <Link to="/ForgotPassword" className=""style={{ backgroundColor:"ffffff", color:"#9b6bd1", borderColor:"#9b6bd1"}}><b>Click Here</b></Link  ></p>
                        <p>Don't have an account ?<Link to="/SignUp" className=""style={{ backgroundColor:"ffffff", color:"#9b6bd1", borderColor:"#9b6bd1"}}><b> Sign Up</b></Link></p>
                        
                       </Form>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
export default Login;