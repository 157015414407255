import React, { useState } from "react";
import { DebounceInput } from 'react-debounce-input';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchApi } from "../services/services";
import { URL } from "../utils/constants";
import { addCelebInfo } from "./../redux/actions/action";
import "./SearchBar.css";

function SearchBar() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");

    const handleFilter = async (event) => {
        const searchWord = event.target.value;
        try {
            if (searchWord === "") {
                setFilteredData([]);
            } else {
                const url = URL + `api/celebrity/search_celeb?searchKeyword=${searchWord}`;
                const data = {
                    url,
                    type: "GET",
                }
                const response = await fetchApi(data);
                const result = await response.json();
                setFilteredData(result);
            }
        }
        catch (e) {
            console.log(e)
        }
        console.log(searchWord)
    };
    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };
    const handleClick = ({ _id }) => {
        history.push(`/celebrity/${_id}`)
        setFilteredData([]);
    }

    return (
        <>
            <DebounceInput
                className="searchInput px-3"
                minLength={1}
                debounceTimeout={300}
                placeholder="Search Celebrity Here"
                onChange={handleFilter} />
            {filteredData.length != 0 && (
                <div className="dataResult">
                    {filteredData.slice(0, 15).map((value, key) => {
                        return (
                            <button className="dataItem" onClick={() => handleClick(value)}>
                                <p>{value.firstName + " " + value.lastName} </p>
                            </button>
                        );
                    })}
                </div>
            )}
        </>
    );
}
export default SearchBar;