import { Container, Row, Col, Card } from "react-bootstrap"
const Banner = () => {
    return (
        <Container fluid >
            {/* <Row noGutters className="justify-content-md-center align-items-md-center py-4">
                <Col  className="d-flex justify-content-center py-2">
                    <Card>
                    <Card  text="white" text-color='#000000' style={{ backgroundImage: "linear-gradient(to right, #F1D265  , #FFF95C )",width: '22rem', height:'7rem', backgroundColor: '#', border: '0px', textAlign:'center', paddingTop:'30px', fontSize:'22px'}}><b style={{color: 'black'}}> Promote Your Business from Celebrity</b></Card>
                    </Card>
                </Col>
                <Col  className="d-flex justify-content-center py-1">
                    <Card>
                    <Card  text="white" style={{backgroundImage: "linear-gradient(to right, #F1D265  , #FFF95C)", width: '22rem', height:'7rem', backgroundColor: '#fad533', border: '0px', textAlign:'center', paddingTop:'30px', fontSize:'22px' }}><b  style={{color: 'black'}}>Take a Personalised wishes from Celebrity</b></Card>
                    </Card>
                </Col>
                <Col     className="d-flex justify-content-center py-1">
                    <Card>
                    <Card text="white" style={{backgroundImage: "linear-gradient(to right, #F1D265  , #FFF95C)", width: '22rem', height:'7rem', backgroundColor: '#fad533', border: '0px', textAlign:'center', paddingTop:'30px', fontSize:'22px'}}><b  style={{color: 'black'}}>Invite Your Celebrity for Events/ Inauguration</b></Card>
                    </Card>
                </Col>
            </Row> */}
            <Row noGutters className="justify-content-md-center align-items-md-center py-4">
                <Col className="d-flex justify-content-center py-1">
                    <Card>
                        <Card.Body className="px-1 py-1 image-wrappe" ><h4 style={{color:"white",  lineHeight: "1.4"}}>Promote Your Business</h4></Card.Body>
                    </Card>
                </Col>
                <Col className="d-flex justify-content-center py-1">
                    <Card>
                        <Card.Body className="px-1 py-1 image-wrappe"><h4  style={{color:"white",  lineHeight: "1.4"}}>Connect with Celebrity</h4></Card.Body>
                    </Card>
                </Col>
                <Col className="d-flex justify-content-center py-1 ">
                    <Card>
                        <Card.Body className="px-1 py-1 image-wrappe"><h4  style={{color:"white",  lineHeight: "1.4"}}>Invite Favourite Celebrity</h4></Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    )
}
export default Banner

