import { Row, Col, Button, Form, Container } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeToken } from "../redux/actions/action";
import SearchBar from './SearchBar';
import logo from "./../assets/logo.png";
import {Link} from "react-router-dom";
const TopBar = () => {
    const dispatch = useDispatch();
    const userToken = useSelector(state => state.authReducer.token);
    const history = useHistory();
    const handleSignup = () => {
        history.push("/SignUp")
    }
    const handleLogin = () => {
        history.push("/login")
    }
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        dispatch(removeToken());
    }
    return (
        <Container fluid >
            <Row noGutters className="justify-content-md-center align-items-md-center">
                <Col md={3} sm={12} className="d-flex justify-content-md-start justify-content-center py-1">
                <Link to="/ForgotPassword" className=""style={{ backgroundColor:"ffffff", color:"#4f989d", borderColor:"#4f989d"}}>
                      <img src={logo} style={{ width: "100%", height: "80%"}} />
                      </Link  >
                </Col>
                <Col md={6} sm={12} className="d-flex justify-content-center py-1">
                    <SearchBar   color="black" style={{ border: "10px",   borderTop:" 1px solid", Color:"black"}} />
                </Col>
                <Col md={3} sm={12} className="d-flex justify-content-center py-1">
                    <div className="d-flex px-1">
                        {!userToken && <Button className="authButton" onClick={handleSignup} >SIGN UP</Button>}
                        {userToken && <Button className="authButton" onClick={handleLogout}>LOGOUT</Button>}
                    </div>
                    <div className="d-flex px-1">
                        {!userToken && < Button className="authButton" onClick={handleLogin}>LOGIN</Button>}
                    </div>
                </Col>
            </Row>
        </Container >
    )
}
export default TopBar