import { Button, Form, Navbar, Nav, NavDropdown, FormControl } from 'react-bootstrap';
const Header = () => {
    return (
        <Navbar expand="lg" sticky="top" style={{ minHeight: "100px",
        background:"linear-gradient(326deg, #a4508b 0%, #5f0a87 74%)", padding: "10px" }}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link className="px-sm-4" href="/">Home</Nav.Link>
                    <Nav.Link className="px-sm-4" href="/">Category</Nav.Link>
                    <Nav.Link className="px-sm-4" href="https://forms.gle/52DExAhzaGTawZa56" target="_blank">Enroll as Celebrity</Nav.Link>
                    <Nav.Link className="px-sm-4" href="https://wa.me/919986516463" target="_blank">For Business</Nav.Link>
                    <Nav.Link className="px-sm-4" href="/">Contact Us</Nav.Link> 
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
export default Header