export const addToken = (data) => {
    return { type: "ADD_TOKEN", payload: data };
}

export const removeToken = () => {
    return { type: "REMOVE_TOKEN" };
}

export const addCelebInfo = (data) => {
    return { type: "ADD_CELEB", payload: data }
}

export const removeCelebInfo = () => {
    return { type: "REMOVE_CELEB" }
}