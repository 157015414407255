import Router from "./router/Router";
import { useDispatch } from "react-redux";
import { addToken, addCelebInfo } from "./redux/actions/action";
import './App.css';

const App = () => {
  const dispatch = useDispatch();
  dispatch(addToken({ token: localStorage.getItem("token"), userId: localStorage.getItem("userId") }));
  dispatch(addCelebInfo(localStorage.getItem("celebInfo") ? JSON.parse(localStorage.getItem("celebInfo")): null));
  return (
    <div className="AppContainer">
      <Router />
    </div>
  );
}

export default App;
