import { useEffect } from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const CelebListComponent = ({ name, scrollId, arrow, celebList, handleArrow, cataegoryId }) => {
    const history = useHistory();
    const handleClick = ({ _id }) => {
        history.push(`/celebrity/${_id}`)
    }
    const handleSeeAll = () => {
        history.push(`/view/${cataegoryId}`)
    }
    return (
        <Container fluid className="py-3">
            <Row noGutters className="px-md-3">
                <Col className="d-flex justify-content-start">
                    <h5>{name}</h5>
                </Col>
                <Col className="d-flex justify-content-end ">
                    <h5 style={{ cursor: "pointer" }} onClick={handleSeeAll}>See All</h5>
                </Col>
            </Row>
            <Row noGutters >
                <Col className="d-flex align-items-center px-0">
                    <div className="d-none d-md-block image-pointer" >
                        {arrow ?
                            <svg style={{ cursor: "pointer" }} onClick={handleArrow} className=".d-none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg>
                            :
                            <div style={{ width: "30px", height: "30px" }} />}
                    </div>
                    <div id={scrollId} className="featured">
                        <ListGroup horizontal>
                            {celebList.map((data, i) =>
                                <ListGroup.Item key={data._id} style={{ cursor: "pointer" }} onClick={() => handleClick(data)}>
                                    <img src={data.profileImage} alt="My Celebrity" className="center imgList1" draggable={false} />
                                    <div className="px-2 text-featured">
                                        <p className="my-0" style={{ fontWeight: "600" }}>{data.firstName + data.lastName}</p>
                                        <p style={{ fontWeight: "lighter", fontSize: "small" }}>{data.firstName}</p>
                                    </div>
                                </ListGroup.Item>
                            )}
                            <ListGroup.Item >
                                <div className="d-flex align-items-center justify-content-center see-all">
                                    <h5 style={{}} onClick={handleSeeAll}>See All</h5>
                                </div>
                            </ListGroup.Item>

                        </ListGroup>
                    </div>
                    <div className="d-none d-md-block image-pointer">
                        {!arrow ? <svg style={{ cursor: "pointer" }} onClick={handleArrow} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                            :
                            <div style={{ width: "30px", height: "30px" }} />
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default CelebListComponent